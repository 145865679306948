import { CopyOutlined } from '@ant-design/icons';
import { Button, Input, notification, Switch } from 'antd';
import { Layout } from 'commons';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
export default class ApiSettingsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiSettings: []
        };
    }

    fetchData = async () => {
        const apiSettings = await fetchAPI('POST', 'external_api/get_auth', null, null, {
            handleErrorInternally: true
        });
        this.setState({
            apiSettings
        });
    };

    saveSettings = async payload => {
        await fetchAPI('POST', 'external_api/set_auth', undefined, payload, {
            handleErrorInternally: true
        });
        await notification.success({
            message: this.props.intl.formatMessage({ id: 'message.success' })
        });
        this.fetchData();
    };

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { user } = this.props;
        const { apiSettings } = this.state;

        const noAccess = !isGrantAccessed(user, grants.SETTINGS_API_PROVIDERS, accesses.ROWO);

        return (
            <Layout controls={[]} title={<FormattedMessage id='navigation.suppliers_API' />}>
                <div className={Styles.settingsWrapper}>
                    {apiSettings.map(
                        ({ active, auth, supplierName, supplierId, login, password, token, settingId }, key) => (
                            <div key={supplierId} className={Styles.settingsElement}>
                                <div className={Styles.settingsElementHeader}>
                                    {supplierName}
                                    <Switch
                                        checked={active}
                                        disabled={noAccess}
                                        onChange={value => {
                                            apiSettings[key].active = value;
                                            this.setState({});
                                        }}
                                    />
                                </div>
                                <div className={Styles.settingsElementRow}>
                                    {supplierName == 'Весна S' ? 'Customer id' : <FormattedMessage id='login' />}
                                    <Input
                                        disabled={auth != 'BASIC' || noAccess}
                                        onChange={({ target }) => {
                                            apiSettings[key].login = target.value;
                                            this.setState({});
                                        }}
                                        placeholder={supplierName === 'Весна S' ? 'customer_id' : undefined}
                                        style={{ width: '65%' }}
                                        value={login}
                                    />
                                </div>
                                <div className={Styles.settingsElementRow}>
                                    {supplierName == 'Весна S' ? (
                                        <FormattedMessage id='token' />
                                    ) : (
                                        <FormattedMessage id='password' />
                                    )}
                                    <Input
                                        disabled={auth != 'BASIC' || noAccess}
                                        onChange={({ target }) => {
                                            apiSettings[key].password = target.value;
                                            this.setState({});
                                        }}
                                        style={{ width: '65%' }}
                                        value={password}
                                    />
                                </div>
                                {supplierName !== 'Весна S' && (
                                    <div className={Styles.settingsElementRow}>
                                        <FormattedMessage id='token' />
                                        <Input
                                            disabled={(auth != 'TOKEN' && supplierName !== 'Весна S') || noAccess}
                                            onChange={({ target }) => {
                                                apiSettings[key].token = target.value;
                                                this.setState({});
                                            }}
                                            style={{ width: '65%' }}
                                            value={token}
                                        />
                                    </div>
                                )}
                                <div className={Styles.settingsElementRow}>
                                    {!noAccess && (
                                        <Link
                                            to={{
                                                pathname: book.waybillsPage,
                                                state: {
                                                    supplier: supplierName
                                                }
                                            }}
                                        >
                                            <CopyOutlined style={{ fontSize: 18 }} />
                                        </Link>
                                    )}
                                    <Button
                                        disabled={noAccess}
                                        onClick={() =>
                                            this.saveSettings({
                                                settingId,
                                                active,
                                                login,
                                                password,
                                                token
                                            })
                                        }
                                        type='primary'
                                    >
                                        <FormattedMessage id='save' />
                                    </Button>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </Layout>
        );
    }
}
